.App {
  text-align: center;
}
body {
  font-family: Roboto,sans-serif;;
}
#root{
  height: 100%;
}

body.fontLoaded {
  font-family: Roboto,sans-serif;;
}

.richTextContainer img{
  max-width: 100%;
}

.ui.form .field > label {
  display: block;
  margin: 0em 0em 0.28571429rem 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.05em;
  font-weight: 500;
  text-transform: none;
  font-family: Roboto;
  margin-top: 1.2em;
}
